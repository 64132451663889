@import './Styles.scss';

.delete-task-form__wrapper {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-task-form {
  max-width: 650px;
  background-color: white;
  border-radius: 25px;
  padding: 50px;
  @media (max-width: 480px) {
    padding: 50px 7.5%;
  }
  h1 {
    font-size: 32px;
    line-height: 1.2em;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 20px;
    line-height: 1.2em;
    color: #8f99a7;
    margin-bottom: 5px;
  }
  .delete-task-form__top {
    display: flex;
    flex-direction: row;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .delete-task-form__topLeft {
      margin-right: 25px;
      @media (max-width: 480px) { 
        margin: 0 0 10px 0;
      }
    }
  }
  .delete-task-form__mid {
    background-color: $light-grey;
    border: 1px solid $blue-grey;
    border-radius: 5px;
    padding: 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
    p {
      margin-left: 15px;
      font-size: 20px;
      line-height: 24px;
      color: #4d5560;
    }
  }
  .delete-task-form__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      flex-grow: 1;
      &:first-child {
        margin-right: 15px;
      }
    }
    @media (max-width: 480px) {
      flex-direction: column-reverse;
      button, button:first-child {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
