@import './Styles.scss';

.chromePrompt {
  margin-top: 25px;
  border: 1px solid $blue-grey;
  padding: 25px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h2 {
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__addButton {
    width: 250px;
    padding: 10px;
    background-color: $blue;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 99px;
    color: white;
    font-weight: 600;
    font-size: 18px;
    img {
      max-height: 30px;
      margin-right: 10px;
    }
  }
  &__dismissButton {
    color: $blue;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 600;
  }
}
