@import './Styles.scss';

.bedtime-wrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   width: 100vw;
}
.bedtime {
   input[type="number"] {
      padding: 10px;
      font-size: 24px;
      font-weight: 500;
      outline: none;
      height: 75px;
      width: 80px;
      margin-right: 10px;
      border-radius: 5px;
      border: 1px solid $blue-grey;
      &::-webkit-inner-spin-button { 
         -webkit-appearance: none;
         cursor: pointer;
     }
     &:hover::-webkit-inner-spin-button {
         background-image: url('../assets/images/numberInput.svg');
         background-repeat: no-repeat;
         width: 18px;
         height: 50px;
         position: relative;
     }
     &:focus {
        border: 1px solid $blue;
     }
}
.bedtime-main {
   background-color: white;
   padding: 50px;
   max-width: 575px;
   border-radius: 10px;
   .bedtime-top {
      display: grid;
      grid-template-columns: 125px 1fr;
      grid-column-gap: 50px;
      margin-bottom: 50px;
   }
   .bedtime-topLeft {
      border-radius: 10px;
      background-color: #3b43d8;
      display: flex;
      align-items: center;
      justify-content: center;
   }
   .bedtime-mid {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
   }
   .bedtime-bottom {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
   }
   h1 {
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      color: #292f36;
      margin-bottom: 10px;
   }
   h2 {
      font-size: 20px;
      line-height: 24px;
      color: #8f99a7
   }
   
   }
   label {
      font-size: 24px;
      font-weight: 500;
      color: #292f36;
      margin-right: 10px;
   }
   
   .bedtime-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
         width: 48%;
      }
   }
}
.bedtime-hourInput {
   display: flex;
   flex-direction: row;
   align-items: center;
}
.bedtime-ampmToggle {
   background-color: $light-grey;
   height: 75px;
   padding: 5px;
   width: 190px;
   border-radius: 75px;
   display: flex;
   flex-direction: row;
   position: relative;
   .bedtime-ampmToggleHighlight {
      position: absolute;
      background-color: white;
      height: 65px;
      width: 90px;
      border-radius: 75px;
      transition: left 0.5s;
      transition-timing-function: cubic-bezier(0.4, 0.0, 0.2, 1);
   }
   .bedtime-ampmToggleHighlight--am {
      left: 5px;
   }
   .bedtime-ampmToggleHighlight--pm {
      left: calc(50%);
   }
   button {
      z-index: 1;
      background-color: transparent;
      height: 65px;
      width: 90px;
      font-weight: 600;
      border-radius: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #8f99a7;
      font-size: 24px;
      transition: color 0.3s;
      &:hover {
         color: #292F36;
      }
   }
   .bedtime-ampmToggleButton--active {
      color: #292f36;
   }
}