@import './Styles.scss';

.viewSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: $light-grey;
  height: 40px;
  padding: 5px;
  border-radius: 25px;
  position: relative;
  &__toggleHighlight {
    position: absolute;
    height: 30px;
    width: calc(50% - 5px);
    background-color: white;
    border-radius: 25px;
    transition: left 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    &[aria-label='full'] {
      width: calc(100% - 10px);
    }
    &[aria-label='summary'] {
      left: 5px;
    }
    &[aria-label='detailed'] {
      left: 50%;
    }
  }
  img {
    opacity: 0.3;
    transition: opacity 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  button {
    z-index: 1;
    width: 50px;
    height: 30px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    &[aria-pressed='true'] {
      img {
        opacity: 1;
      }
    }
  }
}
