@import './Styles.scss';

.week__summary {
  margin-top: 59px;
}

.week__gridRow {
  display: grid;
  grid-template-columns: 180px 1fr 80px;
  grid-template-areas: 'heading grid summary';
  height: 60px;
  align-items: center;
  &__heading {
    grid-area: heading;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      line-height: 1.2em;
      margin-left: 25px;
      color: #4d5560;
      width: 110px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__main {
    grid-area: grid;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__end {
    grid-area: summary;
    text-align: right;
    color: $grey;
  }
  @media (max-width: 480px) {
    grid-template-columns: 4fr 3fr;
    grid-template-areas:
      'heading summary'
      'grid grid';
    grid-row-gap: 10px;
    margin-bottom: 25px;
    &__main {
      grid-column: 1 / span 2;
    }
    &[data-type='heading'] {
      grid-template-columns: min-content 1fr min-content;
      grid-template-areas: 'heading grid summary';
      grid-row-gap: 0;
      align-items: center;
      margin-bottom: 0;
    }
  }
}

.week__gridRowItem {
  height: 30px;
  width: 30px;
  border-radius: 5px;
  line-height: 30px;
  transition: background-color 0.5s;
  &:hover {
    cursor: pointer;
  }
  &[data-type='heading'] {
    &:hover {
      cursor: auto;
    }
    @media (max-width: 480px) {
      line-height: 16px;
      height: 16px;
    }
  }
  &[data-type='future'] {
    background-color: #eef3fb;
    &:hover {
      cursor: auto;
    }
  }
  &[data-type='inactive'] {
    background-color: #e0e7f4;
    transition: all 0.3s ease-in-out;
    &:hover {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      transition: 0.1s;
    }
  }
}
