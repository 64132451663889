$blue: #007bff;
$blue-darker: #0060c7;
$blue-lighter: rgba(0, 136, 255, 0.1);
$blue-grey: #bed1ed;
$red: #eb1f17;
$dark-grey: #292f36;
$grey: #8f99a7;
$darker-grey: #4d5560;
$light-grey: #eef3fb;
$green: #088944;

@font-face {
  font-family: 'Boston';
  font-weight: bold;
  src: url('../assets//fonts/Boston-Bold.woff2') format('woff2'),
    url('../assets//fonts/Boston-Bold.woff') format('woff'), url('../assets//fonts/Boston-Bold.otf') format('opentype'),
    url('../assets//fonts/Boston-Bold.ttf') format('truetype');
}

@mixin button {
  font-size: 20px;
  font-weight: 600;
  padding: 15px;
  border-radius: 60px;
  border: 1px solid transparent;
  outline: none;
  transition: border 0.3s, color 0.3s, background-color 0.3s;
  &:hover {
    cursor: pointer;
  }
}

@mixin standardForm {
  width: 100%;
  font-size: 18px;
  height: 45px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid $blue-grey;
  &:focus {
    border: 1px solid $blue;
  }
  &[aria-invalid='true'] {
    border: 1px solid $red !important;
    &:focus {
      border: 1px solid $red !important;
    }
  }
}
