@import './Styles.scss';
.settingsProfile {
  background-color: white;
  border: 1px solid $blue-grey;
  border-radius: 5px;
  margin-bottom: 50px;
  label {
    align-self: flex-start;
    font-size: 24px;
    font-weight: 500;
    color: $grey;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  h2 {
    padding: 25px;
    font-size: 32px;
    font-weight: 500;
    color: $dark-grey;
    border-bottom: 1px solid $blue-grey;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
    color: $dark-grey;
    @media (max-width: 480px) {
      font-size: 20px;
      margin: 10px 0;
    }
  }
  &__row {
    padding: 25px;
    border-bottom: 1px solid $blue-grey;
    display: grid;
    align-items: center;
    grid-template-columns: 150px 1fr 100px;
    &:last-child {
      border-bottom: 0px;
    }
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
  }
  &__formButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    button {
      border-radius: 50px;
      padding: 15px 50px;
      font-size: 20px;
      font-weight: 500;
      width: calc(50% - 5px);
      text-align: center;
      @media (max-width: 480px) {
        padding: 15px 0;
      }
    }
  }
}

.settingsProfile__form {
  display: flex;
  flex-direction: column;
  @media (max-width: 480px) {
    margin-top: 15px;
    width: 100%;
  }
  label {
    font-size: 20px;
    color: $dark-grey;
    font-weight: 400;
    margin-bottom: 15px;
  }
  input {
    border-radius: 5px;
    outline: none;
    border: none;
    padding: 15px 25px;
    font-size: 24px;
    margin-bottom: 25px;
    width: 100%;
    background-color: $light-grey;
    border: 1px solid transparent;
    background-repeat: no-repeat;
    background-position: 410px 25px;
    &:focus {
      background-image: none;
      background-color: white;
      border: 1px solid $blue;
    }
    &[aria-invalid='false'] {
      background-image: url('../assets/images/inputOkay.svg');
    }
    &[aria-invalid='true'] {
      border: 1px solid $red;
      background-image: url('../assets/images/inputError.svg');
    }
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  .settingsProfile__formSuccess {
    background-color: #e7f5e5;
    border-radius: 5px;
    font-size: 20px;
    margin-top: 0;
    margin-bottom: 15px;
    flex: 1;
    align-items: center;
    padding: 15px;
    display: flex;
    overflow: auto;
    justify-content: space-between;
    color: #0e8d49;
  }
}

.settingsProfileAvatars {
  display: flex;
  flex-direction: row;
  @media (max-width: 480px) {
    margin: 10px 0;
    flex-wrap: wrap;
  }
  &__avatar {
    height: 50px;
    width: 50px;
    padding: 5px;
    border-radius: 50px;
    border: 2px solid transparent;
    img {
      border-radius: 50px;
      height: 100%;
      width: 100%;
    }
    &[aria-selected='true'] {
      border: 2px solid $blue;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &__upload {
    display: none;
  }
}
