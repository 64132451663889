@import './Styles.scss';

.dashboardMobileTabBar {
  position: fixed;
  bottom: 0;
  background-color: $blue;
  height: 75px;
  padding-bottom: unquote('max(0, calc(env(safe-area-inset-bottom) - 20px))');
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  &__button {
    height: 40px;
    margin: 0 25px;
    display: flex;
    flex-direction: column;
    background-color: $blue;
    color: white;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    &[data-active='false'] {
      opacity: 0.5;
    }
  }
}
