@import './Styles.scss';

.dateSelectors {
  display: flex;
  flex-direction: row;
  button {
    height: 40px;
    width: 40px;
    border: 1px solid $blue-grey;
    border-radius: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    transition: border 0.3s;
    img {
      width: 8px;
    }
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      border: 1px solid $blue;
    }
    &[data-inactive='true'] {
      &:hover {
        cursor: default;
        border: 1px solid $blue-grey;
      }
    }
  }
}
