@import './Styles.scss';

.month__main {
  padding-top: 25px;
}

.month__calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-top: 25px;
  border-top: 1px solid $blue-grey;
  border-right: 1px solid $blue-grey;
  hr {
    border-style: none;
    border-bottom: 1px solid $blue-grey;
  }
  &Item {
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-left: 1px solid $blue-grey;
    border-bottom: 1px solid $blue-grey;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    color: $blue-grey;
    &__goal {
      align-self: flex-end;
      font-size: 20px;
      color: $dark-grey;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .month__calendarItem__popup {
    display: none;
    &[data-active='true'] {
      display: block;
      position: absolute;
      bottom: 40px;
      left: -25px;
      box-shadow: 0px 3px 5px $blue-grey;
      padding: 15px;
      border-radius: 5px;
      background-color: white;
      z-index: 1;
      &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        border-top: solid 10px white;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
      }
    }
    &Row {
      display: flex;
      flex-direction: row;
      padding: 5px 0;
      font-size: 16px;
      color: $dark-grey;
      p {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:hover {
        cursor: pointer;
      }
    }
    &RowColor {
      width: 3px;
      margin-right: 10px;
      height: 15px;
    }
    &Row[data-inactive='true'] {
      color: #cad4e2;
    }
    &RowColor[data-inactive='true'] {
      background-color: #cad4e2;
    }
  }

  .month__calendarHeader {
    padding: 10px 0;
    border-bottom: 1px solid $blue-grey;
    text-transform: uppercase;
    color: $blue;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    &:first-child {
      border-left: 1px solid $blue-grey;
    }
  }

  @media (max-width: 480px) {
    margin-top: 0;
    .month__calendarItem {
      padding: 5px;
      height: 50px;
      flex-direction: column-reverse;
      h2 {
        font-size: 14px;
      }
      h3 {
        font-size: 12px;
      }
    }
  }
}
