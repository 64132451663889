@import './Styles.scss';

.userAvatar {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  overflow: hidden;
  img {
    border-radius: 50px;
    width: 100%;
    height: 100%;
  }
}

.loadingPage {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exitButton {
  transition: transform 0.2s;
  padding: 0;
  margin: 0;
  height: 50px;
  width: 50px;
  img {
    height: 50px;
    width: 50px;
  }
  &:hover {
    transform: rotate(90deg);
  }
}

.textOnlyButton {
  @include button;
  padding: 0;
  color: $blue;
  &:hover {
    color: #0054af;
  }
}
.blueButton {
  @include button;
  color: white;
  background-color: $blue;
  &:hover {
    background-color: #0054af;
  }
  &[aria-disabled='true'] {
    opacity: 0.3;
    &:hover {
      background-color: $blue;
      cursor: default;
    }
  }
}
.blueButton--inactive {
  opacity: 0.3;
  &:hover {
    background-color: $blue;
    cursor: default;
  }
}
.whiteButton {
  @include button;
  color: $blue;
  background-color: white;
  border: 1px solid $blue-grey;
  &:hover {
    border: 1px solid $blue;
  }
  &[aria-disabled='true'] {
    opacity: 0.8;
    &:hover {
      border: 1px solid $blue-grey;
      cursor: default;
    }
  }
}

.redButton {
  @include button;
  color: white;
  background-color: $red;
  border: 1px solid $red;
  &:hover {
    background-color: #be0700;
  }
}

.color-indicator {
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
.color-indicator-cross {
  height: 15px;
  width: 15px;
  mask: url('../assets/images/not-to-do.svg');
  mask-size: cover;
}
