@import './Styles.scss';

.dashboardProgressBar {
  display: grid;
  grid-template-columns: 180px 1fr 80px;
  grid-template-areas: 'heading bar summary';
  height: 60px;
  align-items: center;
  &__heading {
    grid-area: heading;
    display: flex;
    flex-direction: row;
    align-items: center;
    span {
      line-height: 1.2em;
      margin-left: 25px;
      color: #4d5560;
      width: 110px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__end {
    grid-area: summary;
    text-align: right;
    color: $grey;
  }
  &__bar {
    grid-area: bar;
    background-color: $light-grey;
    height: 25px;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    position: relative;
    span {
      font-weight: 500;
      position: absolute;
      opacity: 0;
      width: 100%;
      line-height: 25px;
      &:hover {
        cursor: default;
        opacity: 1;
        left: 10px;
        font-size: 12px;
        color: white;
      }
    }
  }
  &__barComplete {
    border-radius: 5px;
    height: 25px;
    width: 0;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s;
  }
  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr 80px;
    grid-template-areas: 'heading summary' 'bar bar';
    grid-row-gap: 10px;
    margin-bottom: 25px;
    &__bar {
      grid-column: 1 / span 2;
    }
  }
}
