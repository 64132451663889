@import './Styles.scss';

.date-range-selector__container {
  margin-bottom: 25px;
  display: grid;
  grid-template-columns: 1fr 180px;
  grid-column-gap: 50px;
  .date-range-selector {
    background-color: $light-grey;
    border-radius: 99px;
    position: relative;
    padding: 6px;
  }
  .date-range-selector ul {
    display: grid;
    height: 100%;
    grid-template-columns: repeat(4, 1fr);
    li {
      color: #9199a6;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: color 0.5s;
      z-index: 1;
      &:hover {
        cursor: pointer;
        color: #292f36;
      }
    }
    li.active {
      color: $dark-grey;
      font-weight: 500;
    }
  }
  .date-range-selector__highlight {
    position: absolute;
    top: 6px;
    left: 6px;
    width: calc(25% - 3px);
    height: calc(100% - 12px);
    background-color: white;
    border-radius: 30px;
    transition: left 0.5s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .date-range-selector__highlight--week {
    left: calc(0% + 6px);
  }
  .date-range-selector__highlight--month {
    left: calc(25% + 3px);
  }
  .date-range-selector__highlight--year {
    left: calc(50% - 3px);
  }
  .date-range-selector__highlight--alltime {
    left: calc(75% - 6px);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    height: 50px;
    .date-range-selector__highlight {
      width: calc(25%);
    }
    .date-range-selector ul li {
      font-size: 14px;
    }
    .whiteButton {
      display: none;
    }
  }
}
