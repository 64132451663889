@import './Styles.scss';

.privacyPolicy,
.terms {
  background-color: $light-grey;
  &__container {
    max-width: 750px;
    margin: 0 auto;
    padding: 50px 0;
    overflow: hidden;
    @media (max-width: 480px) {
      padding: 50px 5%;
    }
  }
  h1 {
    font-size: 48px;
    font-weight: 600;
  }
  h2 {
    font-weight: 500;
    font-size: 24px;
    margin: 40px 0 10px 0;
  }
  h3 {
    font-size: 20px;
    margin-top: 5px;
  }
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 25px 0 10px 0;
  }
  p,
  li {
    font-size: 18px;
    line-height: 1.3em;
    margin-bottom: 5px;
  }
  ol {
    margin-left: 25px;
    list-style: lower-alpha;
  }
}
