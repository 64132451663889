@import './Styles.scss';

.login__wrapper {
  padding: 50px 0 100px 0;
  min-height: calc(100vh - 430px);
  background-color: $blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-weight: 500;
    font-size: 36px;
    color: $dark-grey;
    text-align: center;
    margin-bottom: 25px;
  }
  h2 {
    text-align: center;
    margin-top: 15px;
    font-size: 18px;
    font-weight: 600;
    color: $dark-grey;
    a {
      color: #ffa031;
      padding-bottom: 3px;
      border-bottom: 3px solid #ffa031;
    }
  }
  h3 {
    margin-bottom: 25px;
    font-size: 18px;
    text-align: center;
    font-weight: 400;
  }
  @media (max-width: 600px) {
    padding: 0;
    h1 {
      font-size: 40px;
    }
  }
}
.login__container {
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 5px 30px #0060c7;
  position: relative;
  @media (max-width: 600px) {
    width: 100%;
    padding: 50px 25px;
    box-shadow: none;
    border-radius: 0px;
  }
  .login__forgotPassword {
    color: $blue;
    font-weight: 500;
    margin-bottom: 25px;
    text-align: center;
  }
  .login__separator {
    position: relative;
    hr {
      width: 100%;
      border-bottom: 1px solid $grey;
      margin-bottom: 25px;
    }
    small {
      position: absolute;
      text-align: center;
      width: 50px;
      background-color: white;
      color: $grey;
      left: calc(50% - 25px);
    }
  }
  form {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  input {
    outline: none;
    border: none;
    background-color: $light-grey;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 15px;
    font-size: 18px;
    color: $dark-grey;
    font-weight: 500;
    background-repeat: no-repeat;
    background-position: 360px 25px;
    &:focus {
      background-color: white;
      border: 1px solid $blue;
      background-image: none;
    }
    ::placeholder {
      font-weight: 400;
      color: #4d5560;
    }
    &[aria-invalid='true'] {
      border: 1px solid $red;
      background-image: url('../assets/images/inputError.svg');
    }
  }
  .login__error {
    margin: 0 0 15px 0;
    font-size: 18px;
    color: $red;
    text-align: center;
  }
  .login__success {
    margin: 0 0 15px 0;
    font-size: 18px;
    color: $green;
    text-align: center;
  }
  .login__input--valid {
    background-image: url('../assets/images/inputOkay.svg');
  }
  .login__input--error {
    border: 1px solid $red;
    background-image: url('../assets/images/inputError.svg');
  }
  button {
    position: relative;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
    color: white;
    border-radius: 5px;
    padding: 5px;
    height: 60px;
    img {
      height: 40px;
      position: absolute;
      left: 10px;
      top: 10px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .login__submitButton {
    background-color: $blue;
    &[aria-disabled='true'] {
      background-color: white;
      color: $blue;
      border: 1px solid $blue;
    }
  }
  .login__submitButton--inactive {
    opacity: 0.3;
    &:hover {
      cursor: default;
    }
  }
  .login__googleButton {
    background-color: #4285f4;
  }
  .login__facebookButton {
    background-color: #2d5c98;
  }
  .login__appleButton {
    background-color: black;
  }
  .login__googleButton,
  .login__facebookButton {
    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
    }
  }
}
