@import './Styles.scss';

.delete-form-wrapper {
   display: flex;
   width: 100vw;
   align-items: center;
   justify-content: center;
}
.delete-form {
   background-color: white;
   width: 600px;
   padding: 50px;
   border-radius: 25px;
   display: flex;
   flex-direction: column;
   text-align: center;
   position: relative;
   h1 {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 25px;
   }
   p {
      font-size: 18px;
      line-height: 22px;
   }
   input[type="text"], input[type="password"] {
      margin-top: 25px;
      background-color: $light-grey;
      font-size: 16px;
      padding: 15px;
      border-radius: 5px;
   }
   input[type="password"][aria-invalid=true] {
      border: 1px solid $red;
      background-repeat: no-repeat;
      background-position: calc(100% - 15px) calc(50%);
      background-image: url('../assets/images/inputError.svg');
   }
   &__buttons {
      margin-top: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
         flex-grow: 1;
         &:first-child {
            margin-right: 25px;
         }
      }
   }
   .delete-form__exitButton {
      position: absolute;
      right: 25px;
      top: 25px;
   }
   @media (max-width: 480px) {
      padding: 50px 10%;
      &__exitButton {
         display: none;
      }
      &__buttons {
         margin-top: 10px;
         flex-direction: column;
         button {
            margin-top: 15px;
            width: 100%;
         }
      }
   }
}