@import './Styles.scss';

.main-top {
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  grid-template-areas: 'dateSelector dateRange toggleButtons';
  grid-column-gap: 10px;
  align-items: center;
  .dateSelectors {
    grid-area: dateSelector;
  }
  &__left {
    grid-area: toggleButtons;
    display: flex;
    flex-direction: row;
  }
  h1 {
    grid-area: dateRange;
    font-size: 32px;
    @media (max-width: 1200px) {
      font-size: 24px;
    }
  }
  h2 {
    margin-left: 25px;
    color: $grey;
  }
  .textOnlyButton {
    white-space: nowrap;
    margin-right: 15px;
  }
  @media (max-width: 480px) {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-template-areas: 'dateSelector toggleButtons' 'dateRange dateRange';
    grid-row-gap: 15px;
    &__left {
      justify-self: end;
    }
    h1.main-top-header {
      margin-left: 0;
      font-size: 24px;
    }
    .textOnlyButton {
      display: none;
    }
  }
}

.row-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-start;
  justify-content: flex-start;
  h1 {
    margin-left: 25px;
    color: #4d5560;
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.row-end {
  text-align: right;
  color: $grey;
}

.overall-progress-bar__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding-bottom: 25px;
  border-bottom: 1px solid $blue-grey;
  h3 {
    font-weight: 400;
    font-size: 18px;
  }
  div.overall-progress-bar__percentageComparison {
    img {
      vertical-align: baseline;
      margin-right: 10px;
      height: 16px;
    }
  }
  h3.overall-progress-bar__percentageAchieved {
    text-align: right;
  }
  .overall-progress-bar {
    grid-column: 1 / span 2;
    margin-top: 25px;
    margin-bottom: 15px;
    height: 10px;
    width: 100%;
    background-color: $light-grey;
    border-radius: 5px;
  }
  .overall-progress-bar__complete {
    background-color: $blue;
    height: 10px;
    border-radius: 5px;
    width: 0;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr min-content;
    h3 {
      white-space: nowrap;
      font-size: 14px;
    }
  }
}

div.progress-bar {
  background-color: $light-grey;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  div.progress-bar__complete {
    border-radius: 5px;
    height: 25px;
    width: 0;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition: width 0.3s;
  }
}
