@import './Styles.scss';

.footer {
  background-color: $light-grey;
  box-sizing: border-box;
  font-size: 20px;
  a {
    color: $blue;
  }
  img {
    max-width: 200px;
  }
  &__container {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    padding: 125px 50px;
    grid-template-columns: 1fr 400px;
    grid-column-gap: 25px;
    @media (max-width: 720px) {
      padding: 25px;
      grid-template-columns: 1fr;
      grid-row-gap: 25px;
    }
  }
  &__copyright {
    font-size: 16px;
    color: $grey;
    margin: 20px 0 30px 0;
  }
  &__madeWith {
    font-size: 24px;
    line-height: 1.2em;
    font-weight: 500;
    @media (max-width: 720px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    &TopLeft,
    &TopRight {
      display: flex;
      flex-direction: column;
      text-align: right;
      a {
        color: $dark-grey;
        line-height: 2em;
        font-weight: 500;
      }
      @media (max-width: 480px) {
        text-align: left;
        a {
          font-size: 18px;
        }
      }
    }
    &Bottom {
      grid-column: 1 / span 2;
      justify-self: end;
      a {
        margin: 10px;
      }
      @media (max-width: 480px) {
        justify-self: start;
        a {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}
