@import './Styles.scss';

.onboarding {
  min-height: calc(100vh - 75px);
  padding: 50px 0;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 480px) {
    padding: 25px 5%;
  }
  .bedtime {
    padding-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  h1 {
    font-weight: 500;
    font-size: 48px;
    margin-bottom: 15px;
    @media (max-width: 480px) {
      font-size: 30px;
    }
  }
  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: $grey;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  h3 {
    font-size: 24px;
    color: $dark-grey;
    font-weight: 500;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  p {
    line-height: 1.1em;
    font-size: 24px;
    color: $grey;
    @media (max-width: 480px) {
      font-size: 18px;
    }
  }
  .blueButton {
    width: 250px;
  }
  input {
    @include standardForm;
  }
  .onboarding_errorText {
    color: $red;
  }
}
.onboarding__step0 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h1 {
    margin-top: 25px;
  }
  img {
    max-height: 300px;
  }
}
.onboarding__step1,
.onboarding__step2,
.onboarding__step3 {
  display: grid;
  grid-template-columns: 200px 1fr 300px;
  grid-column-gap: 75px;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
}

.onboarding__step1Right {
  @media (max-width: 480px) {
    flex-direction: column;
    padding-bottom: 50px;
    text-align: center;
    .bedtime {
      flex-direction: column;
      align-items: center;
      input:last-child {
        margin-right: 0;
      }
    }
    .bedtime-ampmToggle {
      margin-top: 25px;
    }
    .blueButton {
      margin-top: 25px;
    }
  }
}
.onboarding__step2Left {
  display: flex;
  flex-direction: row;
}
.onboarding__step2Center {
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    text-align: center;
  }
}

.onboarding__nameSection {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 200px;
  grid-row-gap: 10px;
  margin-top: 25px;
  margin-bottom: 10px;
  @media (max-width: 480px) {
    text-align: left;
    grid-template-columns: 1fr 100px;
  }
  h4 {
    text-align: right;
  }
  input {
    grid-column: 1 / span 2;
  }
  .onboarding__nameRemainingChars {
    position: absolute;
    right: 15px;
    top: 48px;
    color: $grey;
    &[aria-invalid='true'] {
      color: $red;
    }
    @media (max-width: 480px) {
      top: 55px;
    }
  }
}

.onboarding__toDoNotToDoSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
  margin-top: 25px;
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
  h3 {
    grid-column: 1 / span 2;
    margin-bottom: 15px;
  }
  .onboarding__toDoNotToDoInput {
    display: grid;
    grid-template-columns: 25px 1fr;
    align-items: center;
    padding: 15px;
    border: 1px solid $blue-grey;
    border-radius: 5px;
    @media (max-width: 480px) {
      text-align: left;
      margin-top: 10px;
    }
    h4 {
      margin: 0;
      font-size: 24px;
      color: $dark-grey;
      font-weight: 500;
      @media (max-width: 480px) {
        font-size: 20px;
      }
    }
    p {
      margin-top: 10px;
      grid-column: 1 / span 2;
      font-size: 18px;
      line-height: 1.2em;
      color: #616a77;
    }
    &:hover {
      cursor: pointer;
    }
    &[aria-selected='true'] {
      background-color: rgba(0, 123, 255, 0.05);
      border: solid 1px $blue;
    }
  }
}

.onboarding__addedTaskSection {
  background-color: $light-grey;
  border: 1px solid $blue-grey;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  margin-top: 25px;
  padding: 15px 20px;
  h2 {
    font-weight: 400;
    margin-left: 10px;
    max-width: 320px;
    line-height: 1.1em;
    color: $dark-grey;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (max-width: 480px) {
      max-width: 200px;
    }
  }
  button {
    color: $grey;
    font-size: 18px;
    margin-left: auto;
    background-color: transparent;
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
    @media (max-width: 480px) {
      img {
        margin-right: 0;
      }
      span {
        display: none;
      }
    }
  }
}

.onboarding__weeklyFrequencySection {
  margin-top: 25px;
  p {
    font-size: 20px;
    margin-top: 10px;
  }
  input[type='checkbox'] {
    display: none;
  }
  .onboarding__weeklyFrequencySectionTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 480px) {
      flex-direction: column;
      justify-content: center;
      h4 {
        margin-top: 5px;
      }
    }
  }
  .onboarding__weeklyFrequencySectionInputs {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 10px;
    margin-top: 25px;
    @media (max-width: 480px) {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }
  .onboarding__weeklyFrequencySectionButtons {
    display: grid;
    grid-column: 1 / span 7;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 10px;
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
    }
  }
  .checkbox-label {
    @include button;
    display: inline-block;
    font-weight: 500;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $blue-grey;
    background-color: white;
    transition: border 0.3s;
    @media (max-width: 480px) {
      margin-top: 10px;
      font-size: 16px;
    }
    &:hover {
      cursor: pointer;
      border: 1px solid $blue;
    }
    &[aria-selected='true'] {
      color: white;
      border: 1px solid $blue;
      background-color: $blue;
    }
    &[aria-invalid='true'] {
      border: 1px solid $red;
    }
  }
  button {
    @include button;
    font-weight: 500;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid $blue-grey;
    @media (max-width: 480px) {
      font-size: 16px;
    }
    &[aria-pressed='true'] {
      background-color: $blue;
      color: white;
      border: 1px solid $blue;
    }
    &[aria-invalid='true'] {
      border: 1px solid $red;
    }
  }
}

.onboarding__rightSidebar {
  @media (max-width: 480px) {
    display: none;
  }
  h3 {
    margin-bottom: 15px;
    margin-top: 25px;
    &:first-child {
      margin-top: 0;
    }
  }
  .onboarding__presetTask {
    width: 100%;
    border: 1px solid $blue-grey;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 15px;

    h4 {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 400;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.onboarding__buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 50px;
  @media (max-width: 480px) {
    flex-direction: column-reverse;
    .blueButton {
      margin-bottom: 25px;
    }
  }
}
