@import './Styles.scss';

.cancel-subscription-modal__wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cancel-subscription-modal {
  position: relative;
  width: 600px;
  min-height: 360px;
  background-color: white;
  border-radius: 25px;
  padding: 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 32px;
    line-height: 1.2em;
    font-weight: 500;
    margin-bottom: 5px;
  }
  p {
    font-size: 18px;
    line-height: 1.2em;
    color: #8f99a7;
  }
  input[type="text"] {
    width: 100%;
    margin: 25px 0;
    background-color: $light-grey;
    font-size: 16px;
    padding: 15px;
    border-radius: 5px;
 }
}
.cancel-subscription-modal__buttons {
  display: flex;
  flex-direction: row;
  width: 100%;
  button {
    flex-grow: 1;
    &:first-child {
      margin-right: 25px;
    }
  }
}
.cancel-subscription-modal__exitButton {
  position: absolute;
  top: 25px;
  right: 25px;
}
.cancel-subscription-modal--success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .whiteButton {
    margin-top: 25px;
    width: 250px;
  }
}