@import './Styles.scss';
.settingsHelp {
  background-color: white;
  border: 1px solid $blue-grey;
  border-radius: 5px;
  margin-bottom: 50px;
  h2 {
    padding: 25px;
    font-size: 32px;
    font-weight: 500;
    color: $dark-grey;
    border-bottom: 1px solid $blue-grey;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  p {
    font-size: 18px;
    line-height: 22px;
    font-weight: 400;
    color: $grey;
    margin-bottom: 15px;
    a {
      color: $blue;
      font-weight: 600;
    }
  }
  &__bottom {
    padding: 25px;
    a.blueButton {
      margin-top: 25px;
      padding: 20px;
      box-sizing: border-box;
      display: block;
      text-align: center;
      width: 275px;
    }
  }
  @media (max-width: 480px) {
    a.blueButton {
      width: 100%;
    }
  }
}