@import './Styles.scss';

.task-form {
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  flex-shrink: 0;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding: 50px;
  background-color: white;
  width: 625px;
  height: 100vh;
  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 50px 5% 25px 5%;
    border-radius: 0;
  }
  .task-form__exitButton {
    position: absolute;
    right: 50px;
    top: 50px;
    @media screen and (max-width: 480px) {
      top: 25px;
      right: 5%;
    }
  }
  .task-form__colorHighlight {
    float: left;
    width: 10px;
    height: 72px;
    margin-right: 25px;
    border-radius: 10px;
  }
  h2.task-form__mainHeading {
    font-size: 36px;
    font-weight: normal;
    @media screen and (max-width: 480px) {
      font-size: 30px;
    }
  }
  h3.task-form__mainSubheading {
    font-size: 20px;
    font-weight: normal;
    color: $grey;
    margin-top: 15px;
    margin-bottom: 30px;
    @media screen and (max-width: 480px) {
      margin-top: 10px;
      font-size: 18px;
    }
  }
  .task-form__sectionTop {
    padding-bottom: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .task-form__sectionBottom {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .task-form--inputError {
    border: 1px solid $red !important;
    &:focus {
      border: 1px solid $red !important;
    }
  }
  .task-form__errorText {
    color: $red !important;
  }
  hr {
    border-style: none;
    border-bottom: 1px solid $blue-grey;
  }
  h3.task-form__formHeading {
    font-weight: 500;
    font-size: 24px;
    @media screen and (max-width: 360px) {
      font-size: 20px;
    }
  }
  input[type='checkbox'] {
    display: none;
  }
  input::placeholder {
    color: $grey;
  }

  // NAME SECTION
  .task-form__nameSection {
    margin-top: 25px;
    position: relative;
  }
  .task-form__nameSectionBottom {
    position: relative;
  }
  .task-form__instructionText {
    color: $grey;
    text-align: right;
    @media screen and (max-width: 480px) {
      font-size: 12px;
    }
  }
  .task-form__nameInput {
    @include standardForm;
  }
  .task-form__nameRemainingChars {
    position: absolute;
    top: 53px;
    right: 15px;
    color: $grey;
    &[aria-invalid='true'] {
      color: $red;
    }
  }

  // WEEKLY FREQUENCY SECTION
  .task-form__weeklyFrequencySection {
    margin-top: 50px;
  }
  .task-form__weeklyFrequencySectionButtons {
    display: grid;
    grid-column: 1 / span 7;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 15px;
    @media screen and (max-width: 480px) {
      grid-column-gap: 10px;
    }
  }
  .task-form__weeklyFrequencySectionBottom {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    @media screen and (max-width: 480px) {
      grid-column-gap: 10px;
    }
    .checkbox-label {
      display: inline-block;
      width: 100%;
      font-weight: 500;
      height: 45px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        cursor: pointer;
        border: 1px solid $blue;
      }
      @media screen and (max-width: 480px) {
        font-size: 14px;
      }
    }
    .checkbox-label--inactive {
      color: $dark-grey;
      border: 1px solid $blue-grey;
      background-color: white;
      transition: border 0.3s;
      &:hover {
        border: 1px solid $blue;
      }
    }
    .checkbox-label--active {
      color: white;
      border: 1px solid $blue;
      background-color: $blue;
    }
    .task-form__everydayButton {
      border-radius: 5px;
      font-size: 18px;
      color: $dark-grey;
      font-weight: 500;
      height: 45px;
      border: 1px solid $blue-grey;
      margin: 0;
      transition: border 0.3s;
      &:hover {
        border: 1px solid $blue;
      }
    }
    .task-form__everydayButton--active {
      background-color: $blue;
      color: white;
      border: 1px solid $blue;
    }
  }

  // TO DO / NOT TO DO SECTION
  .task-form__toDoNotToDoSection {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-top: 50px;
    label {
      font-size: 18px;
      color: $dark-grey;
      margin-left: 10px;
      margin-right: 25px;
      &:hover {
        cursor: pointer;
      }
      @media screen and (max-width: 480px) {
        font-size: 16px;
      }
    }
    img {
      margin-left: -10px;
    }
    .task-form__toDoNotToDoSectionBottom {
      margin-top: 15px;
      display: flex;
      position: relative;
      flex-direction: row;
      align-items: center;
    }
    .task-form__radioButton {
      display: inline-block;
      height: 24px;
      width: 24px;
      border-radius: 24px;
      border: 1px solid $blue-grey;
      &:hover {
        cursor: pointer;
      }
    }
    .task-form__radioButton--active {
      padding: 5px;
      &::after {
        content: '';
        display: block;
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background-color: $blue;
      }
    }
  }
  .task-form__toDoNotToDoHover {
    position: absolute;
    background-color: white;
    width: 200px;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 0 8px 0 $blue-grey;
    left: 151px;
    top: -118px;
    h4 {
      font-weight: 500;
      font-size: 20px;
      color: $dark-grey;
      margin-bottom: 5px;
    }
    p {
      line-height: 18px;
      font-size: 16px;
      color: $grey;
    }
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-top: solid 10px white;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }
  }

  // BUTTONS
  .task-form__buttons {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    @media screen and (max-width: 480px) {
      flex-direction: column;
      margin-top: 40px;
      button {
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }
  .task-form__submitButton {
    width: 200px;
  }
  .task-form__addAnotherButton {
    width: 200px;
    margin-left: 25px;
  }
}

.task-form__addSuccess {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: #eef3fb;
  height: 70px;
  padding: 0 25px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: flex-start;
  margin-top: 0;
  transition: margin-top 0.3s;
  p {
    margin-left: 15px;
  }
  span {
    font-weight: 500;
  }
  img {
    margin-left: auto;
    &:hover {
      cursor: pointer;
    }
  }
}

// ANIMATIONS
@keyframes animateAddTaskFormEnter {
  0% {
    margin-left: 100vw;
  }
  40% {
    margin-left: 100vw;
  }
  60% {
    margin-left: calc(100vw - 625px);
  }
  100% {
    margin-left: calc(100vw - 625px);
  }
}
@keyframes animateAddTaskFormExit {
  0% {
    margin-left: calc(100vw - 625px);
  }
  40% {
    margin-left: calc(100vw - 625px);
  }
  60% {
    margin-left: 100vw;
  }
  100% {
    margin-left: 100vw;
  }
}
@media (max-width: 625px) {
  @keyframes animateAddTaskFormEnter {
    0% {
      margin-left: 100vw;
    }
    40% {
      margin-left: 100vw;
    }
    60% {
      margin-left: 0;
    }
    100% {
      margin-left: 0;
    }
  }
  @keyframes animateAddTaskFormExit {
    0% {
      margin-left: 0;
    }
    40% {
      margin-left: 0;
    }
    60% {
      margin-left: 100vw;
    }
    100% {
      margin-left: 100vw;
    }
  }
}
.form-modal-enter .form-modal--outsideForm {
  animation-name: animateAddTaskFormEnter;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.form-modal-enter-done .form-modal--outsideForm {
  width: calc(100vw - 625px);
  @media (max-width: 625px) {
    width: 0;
  }
}
.form-modal-exit .form-modal--outsideForm {
  animation-name: animateAddTaskFormExit;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}
.form-modal-exit-done .form-modal--outsideForm {
  width: 100vw;
}
.form-modal-enter {
  opacity: 0;
}
.form-modal-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.form-modal-exit-active {
  opacity: 0;
  transition: opacity 0.3s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.task-form__addSuccess-enter {
  margin-top: -70px;
}
.task-form__addSuccess-enter-done {
  margin-top: 0;
}
.task-form__addSuccess-exit {
  margin-top: -70px;
}
