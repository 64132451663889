@import './Styles.scss';

.pricing__wrapper {
  padding: 50px 0 100px 0;
  background-color: $blue;
  min-height: calc(100vh - 430px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../assets/images/background-confetti.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.pricing {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
  align-items: center;
  h1 {
    text-align: center;
    font-size: 48px;
    font-weight: 500;
    grid-column: 1 / span 3;
    color: white;
    margin-bottom: 100px;
  }
  @media (max-width: 480px) {
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 50px;
    }
  }
}
.pricing__module {
  background-color: white;
  box-shadow: 0 5px 15px 0 $blue-darker;
  border-radius: 10px;
  min-width: 300px;
  @media (max-width: 480px) {
    margin-bottom: 25px;
  }
  .pricing__moduleTop {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 25px 0 0 25px;
    img {
      margin-bottom: 10px;
    }
    span {
      font-size: 24px;
      font-weight: 600;
    }
  }
  .pricing__moduleBottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px;
    h2 {
      font-size: 40px;
      color: $dark-grey;
      font-weight: 500;
      margin-bottom: 5px;
    }
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: $blue;
      margin-bottom: 25px;
    }
    p {
      font-size: 20px;
      color: $grey;
      line-height: 24px;
      margin-bottom: 25px;
    }
    a,
    button {
      width: 100%;
      border-radius: 10px;
    }
    small {
      margin-top: 10px;
      font-size: 16px;
      color: $grey;
    }
  }
  .pricing__moduleBottom2Col {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}
