@import './Styles.scss';

.dashboard__main {
  display: flex;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0 100px 0;
  @media (max-width: 1200px) {
    min-height: calc(100vh - 150px);
    padding: 50px 2.5% 100px 2.5%;
  }
}
.dashboard__top {
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: left;
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
    color: $grey;
    transition: color 0.3s;
    &:hover {
      color: $dark-grey;
      cursor: pointer;
    }
  }
  @media (max-width: 480px) {
    margin: 0;
    padding: 0 0 25px 0;
    h1 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 16px;
    }
  }
}
.dashboard__left {
  flex-grow: 1;
  margin-right: 50px;
}

@media (max-width: 768px) {
  .dashboard {
    overflow-x: auto;
    &__left,
    &__right {
      scroll-snap-align: start;
      width: 100%;
      padding: 25px 5%;
    }
    &__left {
      grid-area: left;
      margin-right: 0;
    }
    &__right {
      grid-area: right;
      display: flex;
      justify-content: center;
    }
  }
  .dashboard__main {
    display: grid;
    grid-template-columns: 100% 100%;
    grid-template-areas: 'right left';
    overflow-x: auto;
    scroll-snap-coordinate: 0 0;
    scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .dashboard {
    padding-bottom: 75px;
  }
}
