@import './Styles.scss';

.habitLimits {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  height: 75px;
  &:hover {
    cursor: pointer;
  }
  &[data-dark='true'] {
    span {
      color: $dark-grey;
    }
    .habitLimits__indicator {
      background-color: $blue-grey;
    }
    .habitLimits__indicator[data-active='true'] {
      background-color: #ffcd45;
    }
  }
  span {
    color: white;
    font-weight: 600;
    margin-left: 10px;
  }
  &__indicators {
    display: flex;
    flex-direction: row;
  }
  &__indicator {
    height: 25px;
    width: 25px;
    border-radius: 5px;
    background-color: white;
    opacity: 0.5;
    margin: 0 5px;
    &[data-active='true'] {
      opacity: 1;
      background-color: #ffcd45;
    }
  }
  &__popup {
    display: flex;
    flex-direction: column;
    justify-items: center;
    position: absolute;
    width: 250px;
    z-index: 1;
    background: $dark-grey;
    color: white;
    top: 65px;
    padding: 25px;
    text-align: center;
    font-weight: 600;
    line-height: 1.3em;
    border-radius: 10px;
    button.blueButton {
      width: 100%;
      height: 60px;
      font-size: 18px;
      margin-top: 15px;
      &:hover {
        color: white;
        background-color: #0054af;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-bottom: solid 10px $dark-grey;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }
  }
}
