@import './Styles.scss';

.header {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 75px;
  z-index: 3;
  background-color: $blue;
  .blueButton:hover {
    background-color: inherit;
    color: $blue-grey;
  }
  &.--white {
    background-color: white;
    .textOnlyButton {
      background-color: white;
    }
    .blueButton:hover {
      background-color: #0054af;
      color: white;
    }
  }
  &.--mobileHidden {
    @media (max-width: 675px) {
      display: none;
    }
  }
  img {
    max-height: 40px;
  }
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 1200px) {
      padding: 0 5%;
    }
  }
  &__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    button {
      height: 50px;
      padding: 5px 30px;
      margin-left: 10px;
    }
    @media (max-width: 675px) {
      display: none;
    }
  }
  &__hamburger {
    display: none;
    @media (max-width: 675px) {
      display: block;
    }
  }
  &__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  &__menuIcon {
    width: 15px;
    margin-left: 10px;
    opacity: 0.5;
    margin-bottom: -5px;
  }
}

.headerDropdown {
  z-index: 3;
  overflow: hidden;
  background-color: white;
  position: absolute;
  right: 0px;
  top: 75px;
  border-radius: 0 0 10px 10px;
  border: solid $blue-grey;
  border-width: 0 1px 1px 1px;
  box-shadow: 0 3px 6px $blue-grey;
  h2 {
    line-height: 19px;
    font-size: 18px;
    font-weight: 500;
    color: #292f36;
    margin-bottom: 3px;
  }
  h3 {
    line-height: 14px;
    font-size: 12px;
    color: #8f99a7;
  }
  &__top {
    padding: 15px;
    display: flex;
    align-items: center;
  }
  &__topRight {
    margin-left: 15px;
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    button {
      border-top: 1px solid $blue-grey;
      padding: 15px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: $dark-grey;
      &:hover {
        background-color: $light-grey;
      }
      &[data-type='upgrade'] {
        color: $blue;
      }
    }
    img {
      margin-right: 15px;
      width: 20px;
      height: 20px;
    }
  }
}

.header__overlay {
  position: fixed;
  height: 100vh;
  width: 100vh;
  top: 0;
  z-index: 5;
  @media (min-width: 675px) {
    display: none;
  }
}

.header__hamburgerMenu {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  &Login {
    display: flex;
    flex-direction: row;
    padding: 25px;
    button {
      padding: 10px 25px;
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  &Section {
    display: flex;
    flex-direction: column;
    padding: 25px 25px 0 25px;
    border-top: 1px solid $blue-grey;
    a {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
      color: $dark-grey;
    }
  }
}

@keyframes animate-in {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
@keyframes animate-out {
  from {
    right: 0;
  }
  to {
    right: -100%;
  }
}
@keyframes opacity-in {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
@keyframes opacity-out {
  from {
    background-color: rgba(0, 0, 0, 0.5);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.header__mobile-enter {
  animation-name: opacity-in;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  .header__hamburgerMenu {
    animation-name: animate-in;
    animation-duration: 0.3s;
  }
}
.header__mobile-exit {
  animation-name: opacity-out;
  animation-duration: 0.3s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  .header__hamburgerMenu {
    animation-name: animate-out;
    animation-duration: 0.3s;
  }
}
.header__mobile-enter-active,
.header__mobile-enter-done {
  background-color: rgba(0, 0, 0, 0.5);
  .header__hamburgerMenu {
    right: 0;
  }
}
.header__mobile-exit {
  background-color: rgba(0, 0, 0, 0.5);
  .header__hamburgerMenu {
    right: 0;
  }
}
.header__mobile-enter-exit-active,
.header__mobile-enter-exit-done {
  background-color: rgba(0, 0, 0, 0);
  .header__hamburgerMenu {
    right: -100%;
  }
}
