@import './Styles.scss';

.payment-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    height: auto;
    overflow: scroll;
  }
}
.payment-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: white;
  width: 650px;
  padding: 50px;
  @media (max-width: 480px) {
    width: 100%;
    padding: 25px 5%;
  }
  h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 52px;
    margin-bottom: 5px;
    @media (max-width: 480px) {
      max-width: calc(100vw - 10% - 75px);
      font-size: 30px;
      line-height: 32px;
      margin-bottom: 10px;
    }
  }
  h2 {
    font-size: 18px;
    line-height: 1.2em;
    color: $grey;
  }
  p.error {
    color: $red;
    margin-bottom: 10px;
  }
  .payment__exitButton {
    position: absolute;
    top: 25px;
    right: 25px;
    @media (max-width: 480px) {
      right: 5%;
    }
  }
  .payment-toggle {
    position: relative;
    margin-top: 25px;
    width: 210px;
    background-color: $light-grey;
    border-radius: 45px;
    height: 50px;
    padding: 5px;
    .payment-toggle__highlight {
      position: absolute;
      width: 100px;
      height: 40px;
      border-radius: 35px;
      background-color: white;
      transition: left 0.5s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    .payment-toggle__highlight--month {
      left: 5px;
    }
    .payment-toggle__highlight--year {
      left: 50%;
    }
    .payment-toggle--inactive {
      color: #9199a6;
    }
    button {
      position: relative;
      z-index: 1;
      width: 100px;
      height: 40px;
      font-weight: 500;
      font-size: 16px;
      background-color: transparent;
      transition: color 0.3s;
      &:hover {
        color: #292f36;
      }
    }
  }

  .payment-container__options {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    margin: 25px 0;
    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #292f36;
      grid-column: 1 / span 2;
      margin-bottom: 10px;
    }
    .payment-container__option {
      padding: 15px;
      border-radius: 5px;
      border: 1px solid $blue-grey;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      h1 {
        color: $blue;
        font-size: 32px;
        line-height: 1em;
        margin-bottom: 0;
        margin-top: 10px;
        font-weight: 600;
      }
      h3 {
        text-align: left;
        font-size: 24px;
        font-weight: 600;
        color: #292f36;
        span {
          font-weight: 500;
          font-size: 16px;
          color: $blue;
        }
      }
      h4 {
        font-size: 16px;
        color: $grey;
        line-height: 25px;
      }
      &[data-active='true'] {
        background-color: rgba(0, 123, 255, 0.05);
        border-color: $blue;
      }
    }
    @media (max-width: 480px) {
      grid-column-gap: 10px;
      .payment-container__options {
        justify-items: flex-start;
      }
    }
  }

  .payment-container__mid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    @media (max-width: 480px) {
      flex-direction: column;
      align-items: flex-start;
      h3 {
        margin-bottom: 15px;
      }
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      span {
        color: $blue;
      }
    }
    img {
      height: 30px;
    }
  }
  .payment-container__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    input[type='text'] {
      height: 50px;
      width: 100%;
      padding: 15px 12px;
      background-color: $light-grey;
      margin-bottom: 25px;
      font-size: 18px;
      border-radius: 5px;
      border: 1px solid transparent;
      &:focus {
        background-color: white;
        border: 1px solid $blue;
      }
    }
    button {
      padding: 15px 0;
      background-color: $blue;
      width: 100%;
      color: white;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 15px;
      border-radius: 5px;
      &:hover {
        background-color: #0054af;
      }
    }
    small {
      text-align: center;
      font-size: 14px;
    }
  }
}

.payment-container--loading {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.payment-container--success {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  //background-image: url('../assets/images/successBg.png');
  justify-content: center;
  align-items: center;
  h1,
  h2 {
    padding: 5px;
    background-color: white;
  }
  img {
    background-color: transparent;
  }
  .blueButton {
    margin-top: 25px;
    width: 300px;
  }
}

.StripeElement {
  height: 50px;
  padding: 15px 12px;
  background-color: $light-grey;
  margin-bottom: 25px;
  border-radius: 5px;
  border: 1px solid transparent;
}
.StripeElement--focus {
  background-color: white;
  border: 1px solid $blue;
}
