@import './Styles.scss';

.dayItem {
  position: relative;
  margin-bottom: 25px;
  display: grid;
  max-width: 275px;
  grid-template-columns: 25px 185px 65px;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  &:last-child {
    margin-bottom: 0px;
  }
  .dayItem__header {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2em;
    margin: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .dayItem__leftSide {
    grid-row: 1 / span 2;
  }
  .dayItem__topLeft {
    display: flex;
    padding-left: 20px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
  }
  .dayItem__topRight {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
  }
  &__streak {
    display: flex;
    flex-direction: row;
    margin-right: 10px;
    img {
      margin-left: 5px;
    }
    &[data-active='true'] {
      p {
        color: white;
      }
    }
  }
  .dayItem__highlight {
    position: absolute;
    height: 100%;
    left: 25px;
    width: 5px;
    border-radius: 40px;
    transition: width 0.1s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    z-index: -1;
  }
  .dayItem__submit {
    grid-column: 2 / span 2;
    padding-left: 20px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .dayItem__completed {
      color: $grey;
      font-size: 16px;
      font-weight: 500;
    }
    .dayItem__inactive {
      color: $blue-grey;
      font-size: 16px;
      font-weight: 500;
    }
    .dayItem__completeButton {
      font-weight: 600;
      font-size: 18px;
      color: $blue;
      width: 100%;
      height: 100%;
      border: 1px solid $blue-grey;
      border-radius: 5px;
      transition: border 0.3s;
      &:hover {
        border: 1px solid $blue;
      }
    }
  }
}

.dayItem[data-status='inactive'] {
  .dayItem__header {
    color: $blue-grey;
  }
}

.dayItem[data-status='completed'] {
  .dayItem__highlight {
    width: calc(100% - 25px);
    border-radius: 10px;
  }
  .dayItem__header {
    color: white;
    margin: 10px 0;
  }
  .dayItem__goal,
  .dayItem__completed {
    color: white;
  }
  .dayItem__topRight {
    margin-left: -15px;
    padding-right: 15px;
  }
  .dayItem__undoButton {
    color: white;
    margin-right: 10px;
    background-color: transparent;
    font-size: 16px;
    font-weight: 600;
    &:hover {
      cursor: pointer;
    }
  }
}

.dayItem__dropDown {
  display: block;
  border-radius: 5px;
  position: absolute;
  right: -23px;
  top: 50px;
  z-index: 1;
  background-color: white;
  border: 1px solid $blue-grey;
  box-shadow: 0px 3px 6px $blue-grey;
  overflow: hidden;
  li {
    padding: 15px;
    text-align: right;
    color: $dark-grey;
    border-bottom: 1px solid $blue-grey;
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      cursor: pointer;
      background-color: $light-grey;
    }
  }
  &:before {
    content: '';
    position: absolute;
    right: -10px;
    top: 0px;
    border: 10px solid white;
    border-color: transparent transparent white white;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: -3px 3px 3px rgba(190, 209, 237, 0.15), -1px 1px 1px $blue-grey;
  }
}
