@import './Styles.scss';

.emailConfirmationNotification {
  width: 100vw;
  background-color: $light-grey;
  height: 40px;
  display: flex;
  justify-content: center;
  span {
    font-weight: 600;
  }
  &__container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  button {
    color: $blue;
    font-size: 16px;
    background-color: transparent;
    line-height: 1.1em;
    &:disabled {
      cursor: auto;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    height: auto;
    padding: 10px 5%;
    button {
    }
  }
}
