@import './Styles.scss';
.settingsSubscription {
  background-color: white;
  border: 1px solid $blue-grey;
  border-radius: 5px;
  margin-bottom: 50px;
  h2 {
    padding: 25px;
    font-size: 32px;
    font-weight: 500;
    color: $dark-grey;
    border-bottom: 1px solid $blue-grey;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  &__top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    border-bottom: 1px solid $blue-grey;
    h2 {
      padding: 0;
      border: none;
    }
    h4 {
      margin-left: 5px;
      text-align: right;
      @media (max-width: 480px) {
        font-size: 14px;
      }
    }
    @media (max-width: 480px) {
      h4 {
        margin-left: 25px;
      }
    }
  }
  &__row {
    padding: 25px;
    position: relative;
    h3 {
      font-size: 24px;
      font-weight: 500;
      color: $dark-grey;
      margin-bottom: 25px;
    }
    h4 {
      margin-top: 25px;
      color: $dark-grey;
    }
    p {
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      color: $grey;
      margin-bottom: 15px;
    }
    p.error {
      color: $red;
    }
    input[type='text'] {
      height: 50px;
      width: 100%;
      padding: 15px 12px;
      background-color: $light-grey;
      margin-bottom: 25px;
      font-size: 18px;
      border-radius: 5px;
      border: 1px solid transparent;
      &:focus {
        background-color: white;
        border: 1px solid $blue;
      }
    }
  }
  &__formButtons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 25px;
    button {
      width: 275px;
      margin-right: 25px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      button {
        width: 100%;
        margin-bottom: 15px;
      }
    }
  }
  &__loadingLayer {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    opacity: 0.5;
  }
  &__paymentFormTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    img {
      max-height: 30px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      margin-bottom: 25px;
    }
  }
  &__paymentInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    background-color: $light-grey;
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    img {
      max-height: 30px;
      margin-right: 10px;
    }
  }
}

.settingsSubscription__plan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  width: 100%;
  border: 1px solid $blue-grey;
  border-radius: 5px;
  margin-bottom: 25px;
  &:hover {
    cursor: pointer;
  }
  &[aria-selected='true'] {
    border: 2px solid $blue;
  }
  &Buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
    button {
      width: 275px;
    }
  }
  h4 {
    color: $grey;
    font-size: 24px;
    margin: 0 0 10px 0;
    strong {
      font-weight: 500;
      color: $dark-grey;
    }
    span {
      color: $blue;
      font-size: 20px;
      font-weight: 600;
      margin-left: 10px;
    }
  }
  p {
    margin: 0;
  }
  button {
    padding: 0;
    height: 50px;
    width: 200px;
  }
  .blueButton:hover {
    background-color: $blue;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    button {
      margin-top: 15px;
    }
    h4 span {
      margin-top: 5px;
      margin-left: 0;
    }
    &Buttons {
      flex-direction: column;
      margin-top: 0px;
      button {
        width: 100%;
        margin-top: 15px;
      }
    }
  }
}
