@import './Styles.scss';

.day__container {
  flex-grow: 0;
  position: sticky;
  width: 325px;
  top: 50px;
  align-self: flex-start;
}
.day__top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  .textOnlyButton {
    position: absolute;
    right: 100px;
  }
  .day-top__dateHeader {
    text-align: center;
    font-size: 24px;
  }
}
.day__main {
  padding: 25px;
  border: 1px solid $blue-grey;
  border-radius: 5px;
}

// NO TASKS OR BEDTIME STATES
.day-main__noTasksToday {
  img {
    max-width: 100%;
  }
}
.day-main__noTasksTodayHeader {
  font-size: 24px;
  margin-bottom: 5px;
}
.day-main__noTasksTodaySubheader {
  font-size: 18px;
  line-height: 22px;
  color: $grey;
}
.day-main--noTasks {
  button {
    width: 100%;
    padding: 10px 25px;
    background-color: $light-grey;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  img {
    height: 50px;
    margin-right: 25px;
  }
}
.day-main--noBedtime {
  button {
    width: 100%;
    padding: 20px 25px;
    background-color: #3b43d8;
    color: white;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin-bottom: 15px;
  }
  img {
    height: 30px;
    margin-right: 25px;
  }
}

.day-addTask--mobile {
  width: 100%;
  padding-top: 25px;
  .whiteButton {
    width: 100%;
  }
  @media screen and (min-width: 480px) {
    display: none;
  }
}

.day-mobilePrompt--mobile {
  border-radius: 5px;
  border: solid 1px $blue-grey;
  padding: 20px;
  text-align: center;
  margin-top: 25px;
  h1 {
    font-size: 24px;
    margin-bottom: 10px;
    font-weight: 500;
    color: $dark-grey;
  }
  p {
    font-size: 16px;
    color: $grey;
  }
  @media screen and (min-width: 480px) {
    display: none;
  }
}

.day__expired {
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
  }
  h3 {
    margin-top: 5px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  &Item {
    background-color: $light-grey;
    border: 2px solid $light-grey;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    p {
      margin-left: 10px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  &Item[data-selected='true'] {
    border: 2px solid $blue;
    background-color: $blue-lighter;
  }
  &Item[data-error='true'] {
    border: 2px solid $red;
  }
  button {
    font-weight: bold;
    font-size: 18px;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
  }
  &SubscribeButton {
    background-color: $blue;
    color: white;
  }
  &ContinueButton {
    border: 1px solid $blue-grey;
    color: $blue;
  }
}
