@import './Styles.scss';

.settings {
  background-color: $light-grey;
  min-height: 100vh;
  padding: 50px 0;
  position: relative;
  @media (max-width: 480px) {
    padding: 50px 2.5%;
  }
}

.settings__container {
  margin: 0 auto;
  max-width: 750px;
  h1 {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 50px;
    color: $dark-grey;
    @media (max-width: 480px) {
      font-size: 30px;
    }
  }
}

.settingsDelete {
  display: flex;
  justify-content: center;
  transition: border 0.3s;
  background-color: white;
  border: 1px solid $blue-grey;
  border-radius: 5px;
  margin-bottom: 50px;
  h2 {
    padding: 25px;
    font-size: 24px;
    font-weight: 400;
    color: $red;
    border: none;
  }
  &:hover {
    cursor: pointer;
    border: 1px solid $red;
  }
}

.settingsNotification {
  transition: height 0.3s;
  background-color: #e7f5e5;
  border-radius: 5px;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 15px;
  flex: 1;
  align-items: center;
  padding: 15px;
  display: flex;
  overflow: auto;
  justify-content: space-between;
  color: #0e8d49;
  overflow: hidden;
  img:hover {
    cursor: pointer;
  }
}
.settingsNotification-enter {
  height: 0px;
}
.settingsNotification-enter-active {
  height: 50px;
}
.settingsNotification-enter-done {
  height: 50px;
}
.settingsNotification-exit {
  height: 50px;
}
.settingsNotification-exit-active {
  height: 0px;
}
.settingsNotification-exit-done {
  height: 0px;
}
