@import './Styles.scss';

.index__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  min-width: 100%;
  h1 {
    font-size: 52px;
    line-height: 1.1em;
    font-family: 'Boston';
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #292f36;
    @media (max-width: 600px) {
      font-size: 36px;
    }
  }
  h2 {
    line-height: 1.25em;
    font-size: 24px;
    font-weight: 500;
    color: #808d9f;
    margin-top: 24px;
    margin-bottom: 50px;
  }
  h3 {
    font-size: 40px;
    font-weight: 500;
    font-family: 'Boston';
    line-height: 1.25em;
    @media (max-width: 600px) {
      font-size: 36px;
    }
  }
  p {
    font-size: 24px;
    margin-top: 16px;
    line-height: 1.25em;
    color: #808d9f;
    font-weight: 500;
  }
  .index-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .index-container {
    max-width: 1200px;
    padding: 100px 0;
    @media (max-width: 1200px) {
      padding: 50px 2.5%;
    }
  }
  .index-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a,
    button {
      margin: 0 10px;
    }
    @media (max-width: 480px) {
      flex-direction: column;
      a {
        margin: 10px 0;
        display: block;
      }
    }
  }
  .index__ctaButton {
    width: 225px;
    height: 65px;
    padding: 15px 10px;
    align-self: center;
    background-color: #017bff;
    border-radius: 60px;
    font-size: 20px;
    font-weight: 700;
    color: #ffffff;
    transition: all 0.3s ease-in-out;
  }
  .index__ctaButton-alt {
    width: 225px;
    height: 65px;
    padding: 15px 10px;
    align-self: center;
    background-color: #ffffff;
    border-radius: 60px;
    font-size: 20px;
    font-weight: 700;
    color: #017bff;
    transition: all 0.3s ease-in-out;
  }
  .index__chromeButton {
    width: 225px;
    height: 65px;
    padding: 15px 10px;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: white;
    border-radius: 60px;
    font-size: 20px;
    font-weight: 700;
    border: 1px solid $blue;
    color: $blue;
    transition: all 0.3s ease-in-out;
    img {
      max-height: 30px;
      margin-right: 10px;
    }
  }
  .index__ctaButton-alt:hover,
  .index__ctaButton-alt:focus {
    box-shadow: 0 12px 15px 0 rgba(0, 104, 216, 1);
    transform: translateY(-0.15em);
  }
  .index__ctaButton:hover,
  .index__ctaButton:focus,
  .index__chromeButton:hover,
  .index__chromeButton:focus {
    box-shadow: 0 12px 15px 0 rgba(0, 123, 255, 0.25);
    transform: translateY(-0.15em);
  }
  .index__appStoreButton {
    border: none;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
  }
  .index__appStoreButton:hover,
  .index__appStoreButton:focus {
    box-shadow: 0 12px 15px 0 rgba(41, 47, 54, 0.25);
    transform: translateY(-0.15em);
  }
}

// HERO
.index-hero {
  width: 100%;
  background: white;
  background-image: url('../assets/images/background-confetti.svg');
  background-repeat: no-repeat;
  background-position: top center;
  h1 {
    font-size: 72px;
    @media (max-width: 480px) {
      font-size: 45px;
    }
  }
  h2 {
    max-width: 800px;
    font-size: 36px;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 960px;
    img {
      max-width: 80%;
    }
  }
}

// SECTION 1 - BACKGROUND IMAGES
.index-section1 {
  background-image: linear-gradient(white, #f2f4f7);
  padding-bottom: 100px;
  h2 a {
    color: $blue;
    font-weight: 600;
  }
  @media (max-width: 1200px) {
    padding: 0 2.5%;
  }
  &__images {
    margin-bottom: 50px;
    position: relative;
    @media (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 5px;
    }
    &Desktop {
      background-color: white;
      border-radius: 15px;
      padding-top: 20px;
      width: 100%;
      position: relative;
      left: -25px;
      max-width: 1050px;
      box-shadow: 0 0 30px rgba(149, 173, 209, 0.64);
      @media (max-width: 768px) {
        left: 0;
        border-radius: 5px;
      }
    }
    &Mobile {
      position: absolute;
      right: -25px;
      bottom: -40px;
      width: 25%;
      max-width: 275px;
      z-index: 1;
      border-radius: 25px;
      @media (max-width: 768px) {
        width: 275px;
        position: relative;
        bottom: 0;
        right: 0;
      }
    }
    &MobileFrame {
      position: absolute;
      right: calc(-25px - 2.5%);
      bottom: calc(-40px - 2%);
      width: 29.8%;
      z-index: 1;
      @media (max-width: 768px) {
        width: 325px;
        position: absolute;
        top: -25px;
        left: calc(50% - 137px - 25px);
      }
    }
    .index-buttons {
      display: none;
      @media (max-width: 768px) {
        display: block;
        padding: 50px 0;
      }
    }
  }
}

// SECTION 2 - FEATURES
.index-section2 {
  background-image: url('../assets/images/background.svg');
  background-position: 0 500px;
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 50px 0 100px 0;
  @media (max-width: 1200px) {
    background-size: cover;
  }
  h1 {
    grid-column: 1 / span 2;
    padding-bottom: 50px;
    @media (max-width: 1200px) {
      grid-column: 1;
    }
  }
  h3 {
    margin-top: 40px;
  }
  p {
    margin-bottom: 30px;
  }
  &__floating {
    position: absolute;
    right: -50px;
    top: -140px;
    @media (max-width: 480px) {
      display: none;
    }
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 50px;
    width: 1000px;
    @media (max-width: 1200px) {
      grid-template-columns: 1fr;
      width: 100%;
      justify-items: center;
    }
  }
  &__section {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    text-align: left;
    padding: 32px;
    box-shadow: 0 5px 25px rgba(0, 96, 199, 0.3);
    @media (max-width: 1200px) {
      width: 90%;
      max-width: 500px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    @media (max-width: 480px) {
      p {
        font-size: 18px;
      }
      img {
        max-width: 100%;
      }
    }
  }
  &__sectionIcon {
    width: 60px;
    height: 60px;
  }
}

// SECTION 3 - PRICING SECTION
.index-pricing {
  padding: 50px 0 0 0;
  &__container {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-column-gap: 100px;
    align-items: center;
    &Left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      p {
        margin-bottom: 25px;
      }
      @media (max-width: 1000px) {
        margin: 0 5%;
        align-items: center;
        text-align: center;
      }
    }
    &Right {
      display: flex;
      flex-direction: row;
      @media (max-width: 1000px) {
        justify-content: center;
      }
      @media (max-width: 480px) {
        flex-direction: column;
        margin: 0 5%;
      }
    }
    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $light-grey;
    margin-left: 25px;
    padding: 25px;
    border-radius: 10px;
    position: relative;
    h1 {
      font-size: 36px;
    }
    h2 {
      font-weight: bold;
      color: black;
      margin: 0;
    }
    h3 {
      font-size: 30px;
      margin-top: 10px;
    }
    h4 {
      color: $grey;
    }
    ul {
      margin-top: 15px;
      padding: 0 0 0 25px;
      text-align: left;
      list-style: url('../assets/images/indexCheck.svg');
    }
    li {
      color: black;
      font-weight: 500;
      line-height: 1.5em;
    }
    &Pricing {
      display: grid;
      grid-template-columns: min-content min-content;
      grid-column-gap: 25px;
      div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    @media (max-width: 1000px) {
      margin: 20px 10px 0 10px;
    }
    @media (max-width: 480px) {
      margin: 20px 0 0 0;
    }
  }
  &__pill {
    position: absolute;
    right: 25px;
    background-color: #f7bf0f;
    padding: 5px 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 25px;
  }
}

// SECTION 4 - ANIMATION SECTION
.index-animation {
  background: white;
  width: 100%;
  overflow: hidden;
  h2 {
    max-width: 500px;
    margin-bottom: 0px;
  }
  &__container {
    padding-bottom: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

// SECTION 5 - ANIMATION SECTION
.index-section5 {
  background-image: url('../assets/images/indexSection5Background.svg');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  h2 {
    max-width: 500px;
  }
  img {
    margin-bottom: 50px;
    @media (max-width: 480px) {
      width: 80%;
    }
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.index-analytics {
  h2 {
    max-width: 600px;
  }
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__image {
    width: 100%;
  }
}
