.header__notification {
  position: absolute;
  z-index: 2;
  width: 100vw;
  height: 40px;
  top: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #088944;
  background-color: #e7f5e5;
  transition: top 0.5s;
  .header__notificationContainer {
    position: relative;
    width: 1200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      margin-left: 10px;
    }
  }
}
.header__notification-enter {
  top: 35px;
}
.header__notification-enter-done {
  top: 75px;
}
.header__notification-exit {
  top: 35px;
}
