@import url('https://use.typekit.net/upj1gvk.css');
@import './Styles.scss';

body {
  font-family: proxima-soft, 'Helvetica Neue', 'Roboto', sans-serif;
  color: $dark-grey;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
div,
input {
  box-sizing: border-box;
}
input {
  font-family: proxima-soft, 'Helvetica Neue', 'Roboto', sans-serif;
  outline: none;
  border: none;
}
button {
  font-family: proxima-soft, 'Helvetica Neue', 'Roboto', sans-serif;
  background-color: white;
  outline: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
}
a {
  text-decoration: none;
  color: inherit;
}
.mobile-only {
  display: none;
  @media screen and (max-width: 480px) {
    display: flex;
  }
}

.form-modal {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100vw;
  height: 100vh;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
}
